import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import React from 'react'
 
const TestFiles = props => {
   
      const handleChangeStatus = ({ meta }, status) => {
        console.log(status, meta)
      }
    
      const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
      }
    
      return (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          maxFiles={3}
          inputContent="Afegeix 3 Fitxers"
          inputWithFilesContent={files => `${3 - files.length} més`}
          submitButtonDisabled={files => files.length < 3}
        />
      )
   
}

export default TestFiles;